<template>
  <div>
    <h4>จัดการ Redeem Coin</h4>
    <br />
    <div class="d-flex justify-content-end mb-1">
      <b-btn variant="primary" @click="clickAddRedeem">เพิ่มแคมเปญ</b-btn>
    </div>
    <b-card>
      <table-local-v-1-widget
        :items="listRedeemGroup"
        :columns="columns"
        :loading="loading"
        :actions="actions"
        @clickView="clickView"
        @clickDelete="clickDelete"
      >
        <template #is_active="{row}">
          <div>
            <b-badge :variant="row.is_active ? 'success' : 'secondary'">
              {{ row.is_active ? 'เปิดใช้งาน' : 'ปิดใช้งาน' }}
            </b-badge>
          </div>
        </template>
      </table-local-v-1-widget>
    </b-card>

    <b-modal
      ref="refModalRedeemCoinManagement"
      title="จัดการ Redeem Coin"
      size="md"
      hide-footer
      centered
      no-close-on-backdrop
      modal-class="modal-primary"
    >
      <FormCreateCampaignRedeemCoin @onSubmit="onSubmit" />
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {
    FormCreateCampaignRedeemCoin: () => import('./components/FormCreateCampaignRedeemCoin.vue'),
  },
  data() {
    return {
      columns: [
        {
          label: 'แคมเปญ',
          field: 'name',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'หมายเหตุ',
          field: 'detail',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'จำนวน Code',
          field: 'count_code',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'สถานะการใช้งาน',
          field: 'is_active',
          tdClass: 'text-center',
          thClass: 'text-center',
          type: 'custom',
        },
        {
          label: 'วันที่เปิดใช้งาน',
          field: 'start_date',
          formatFn: val => this.$date(val).format('DD-MM-YYYY HH:mm'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'วันหมดอายุ',
          field: 'end_date',
          formatFn: val => {
            if (val) return this.$date(val).format('DD-MM-YYYY HH:mm')

            return 'ไม่มีวันหมดอายุ'
          },
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      listRedeemGroup: [],
      loading: false,
      actions: [
        { keyEmit: 'clickView', iconName: 'EyeIcon', label: 'ดูเพิ่มเติม' },
        { keyEmit: 'clickDelete', iconName: 'TrashIcon', label: 'ลบ' },
      ],
    }
  },
  mounted() {
    this.fetchListRedeemGroup()
  },
  methods: {
    clickAddRedeem() {
      this.$refs.refModalRedeemCoinManagement.show()
    },
    async onSubmit(values) {
      this.gOpenPageLoading()
      const resp = await this.api.postV2('api/admin/redeeming-pocket-money', { ...values })
      this.gClosePageLoading()

      if (!resp) return

      if (resp.code === 200) {
        this.$refs.refModalRedeemCoinManagement.hide()
        this.fetchListRedeemGroup()
        this.gDisplayToast('เพิ่มแคมเปญสำเร็จ', '', 'success')
      } else {
        this.gDisplayToast('เพิ่มแคมเปญไม่สำเร็จ', resp?.data?.message, 'danger')
      }
    },
    async fetchListRedeemGroup() {
      // console.log(this.$store.getters['AuthStore/_userRole'])
      // return
      this.loading = true

      const resp = await this.api.getV2('api/admin/redeeming-pocket-money').catch(() => null)
      if (resp && resp.code === 200) {
        this.listRedeemGroup = [...resp.data]
      }

      this.loading = false
    },
    async clickView(values) {
      this.$router.push({ name: 'redeem-coin-management-details', params: { redeemMainId: values.id } })
    },
    async clickDelete(values) {
      const resp = await this.api.deleteV2(`api/admin/redeeming-pocket-money/${values.id}`, this)

      if (!resp) return

      if (resp?.message) {
        this.gDisplayToast('ลบแคมเปญไม่สำเร็จ', resp?.message, 'danger')
      } else {
        this.gDisplayToast('ลบแคมเปญสำเร็จ')
      }
      this.fetchListRedeemGroup()
    },
  },
}
</script>

<style lang="scss" scoped></style>
